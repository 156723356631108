import * as React from "react";
import * as style from "./styles/contact.module.scss";
import "../styles/globals.scss";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import PageWrapper from "components/PageWrapper";
import HeaderSection from "components/HeaderSection/";
import BoundedSection from "components/BoundedSection";
import MutableCursorArea from "components/MutableCursorArea";

import LocalPhoneTwoToneIcon from "@mui/icons-material/LocalPhoneTwoTone";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import ShareTwoToneIcon from "@mui/icons-material/ShareTwoTone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";

const ContactInfoBlock = ({ icon, title, children }) => (
  <div className={style.ContactInfo_block}>
    <h3 className={style.ContactInfo_blockTitle}>
      {icon}
      {title}
    </h3>
    <div className={style.ContactInfo_blockData}>{children}</div>
  </div>
);

const ContactInfo = ({ t }) => (
  <div className={style.ContactInfo}>
    <ContactInfoBlock
      icon={<LocalPhoneTwoToneIcon />}
      title={t("contact.phones")}
    >
      <p>
        <MutableCursorArea Tag="a" href="tel:+34881893736">
          +34 881 893 736
        </MutableCursorArea>
      </p>
      <p>
        <MutableCursorArea Tag="a" href="tel:+34622775510">
          +34 622 775 510
        </MutableCursorArea>
      </p>
    </ContactInfoBlock>
    <ContactInfoBlock icon={<EmailTwoToneIcon />} title={t("contact.email")}>
      <p>
        <MutableCursorArea Tag="a" href="mailto:joinus@trileucosolutions.com">
          info@trileucosolutions.com
        </MutableCursorArea>
      </p>
    </ContactInfoBlock>
    <ContactInfoBlock
      icon={<BusinessTwoToneIcon />}
      title={t("contact.address")}
    >
      <p>
        <MutableCursorArea
          Tag="a"
          href="https://goo.gl/maps/cHPDVxjQc1HskRev9"
          target="_blank"
          rel="noopener noreferrer"
        >
          Rúa Ánade Real 11,
          <br />
          Oleiros, A Coruña
          <br />
          15172
        </MutableCursorArea>
      </p>
    </ContactInfoBlock>
    <ContactInfoBlock icon={<ShareTwoToneIcon />} title={t("contact.social")}>
      <div className={style.SocialLinks}>
        <MutableCursorArea
          Tag="a"
          href="https://www.linkedin.com/company/trileuco-solutions/"
        >
          <LinkedInIcon />
        </MutableCursorArea>
        <MutableCursorArea Tag="a" href="https://twitter.com/trileuco?lang=es">
          <XIcon />
        </MutableCursorArea>
      </div>
    </ContactInfoBlock>
  </div>
);

const ContactForm = ({ t }) => <div className={style.ContactForm}></div>;

const ContactPage = () => {
  const { t } = useTranslation();
  return (
    <PageWrapper
      seoTitle={t("contact.seo.title")}
      seoDescription={t("contact.seo.description")}
    >
      <HeaderSection
        className={style.ContactHeader}
        preTitle={t("contact.header.preTitle")}
        title={t("contact.header.title")}
      />
      <BoundedSection boundedClassName={style.ContactInfoWrapper}>
        <ContactInfo t={t} />
        {/* <ContactForm t={t} /> */}
      </BoundedSection>
    </PageWrapper>
  );
};

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
